import { ISAVector } from './assays';
import { Gene, Indication } from './indications';

export interface VectorFeature {
  name: string;
  type: string;
  start: number;
  end: number;
  strand: number;
  notes: Record<string, string[]>;
  locations: Array<{ start: number; end: number }>;
  featureSets?: string[];
  color?: string;
}

export type IndicationMap = {
  [key: string]: {
    [key: string]: FullVector[];
  };
};

export interface FullVector extends ISAVector {
  frn: string;
  type: string;
  risk?: number;
  isaVectorId?: string;
  sequence: string;
  features: VectorFeature[];
  entityVersionId?: string;
  indication?: Indication;
  gene?: Gene;
  scores?: { [_: string]: string | number };
}

export type FileVectorRow = {
  id: string;
  field: string;
  value: string;
  fileType: string;
  vectorId: string;
  vectorName: string;
};

export type VectorBulkUpload = {
  file: File;
  orgId: string;
  pid: string;
  geneId: string;
};

export type VectorBulkUploadResponse = {
  entitiesCreated: number;
  errors?: Record<string, string[]>;
};

/**
 * TBD: we can mark a vector as the origing vector
 * when running genvec or when a vector is the source
 * we want to compare other vectors to.
 * Add this key to a vector profile where needed.
 */
export const PROFILE_KEY_TAGS = 'tags';
export const PROFILE_TAG_ORIGN_VECTOR = 'origin';
export const PROFILE_NAME_TAGS = 'Tags';
