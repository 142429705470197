/**
 * This file represents ALL routes that the UI navigates to.
 * If you want to build a link or router.push (use links when possible),
 * use navRoutes.get("MY_NAV_ROUTE_ID")
 *
 * The helper function getHref will generate a proper href to pass to RouterLink
 * from the current route.
 */
import { NextRouter } from 'next/router';
import { UrlObject } from 'url';
import { NavRoute } from '@formbio/ui/types/routes';
import { AccordionItem, NavItemNode, NavigableItem } from '@formbio/ui/types';
import { getQueryParams } from '@/hooks/useUrlParams';

// avoid passing QueryParams everywhere
export type NavRouteWithParam = NavRoute<QueryParams>;
export type AccordionItemWithParams = AccordionItem<QueryParams>;
export type NavigableItemWithParams = NavigableItem<QueryParams>;
export type NavItemNodeWithParams = NavItemNode<QueryParams>;

export enum QueryParams {
  orgId = 'orgId',
  pid = 'pid',
  runId = 'runId',
  groupKey = 'groupKey',
  email = 'email',
  mode = 'mode',
  oobCode = 'oobCode',
  // TODO: Remove runName after creating step-based workflow builder
  runName = 'runName',
  // for search box queries
  search = 'search',
  // for keeping track of which tab is selected
  tab = 'tab',
  // for searching users
  users = 'users',
  // path for file management directory
  path = 'path',
  // destination page after login
  destination = 'destination',
  // workflow category picked by user
  category = 'category',
  status = 'status',
  previousPageParams = 'previousPageParams',
  // name of repo for a workflow
  repoName = 'repoName',
  workflowName = 'workflowName',
  // workflow version
  version = 'version',
  // uploaded org and proj indicate those to which a workflow is uploaded to
  uploadedOrg = 'uploadedOrg',
  uploadedProj = 'uploadedProj',
  // sign up invitation id (invite token)
  invitationId = 'invitationId',
  // view: organizations/{org}/projects/{proj}/members
  // param: changing tab
  memberType = 'memberType',
  referenceId = 'referenceId',
  encodedFiles = 'encodedFiles',
  workflowId = 'workflowId',
  // firebase
  apiKey = 'apiKey',
  lang = 'lang',
  checkSuiteId = 'checkSuiteId',
  journeyId = 'journeyId',
  phaseRunId = 'phaseId',
  stepRunId = 'stepId',
  journeyRunId = 'journeyRunId',
  download = 'download',
  formBenchId = 'formBenchId',
  selectedFile = 'selectedFile',
  selectedFolder = 'selectedFolder',
  editingFile = 'editingFile',
  frn = 'frn',
  // molstar
  structureUrl = 'structureUrl',
  structureUrlFormat = 'structureUrlFormat',
  pdbId = 'pdbId',
  // when a file is selected, it might get associated
  // with an index file or another potential supporting file
  supportFile = 'supportFile',
  logout = 'logout',
  panel = 'panel',
  indicationId = 'indicationId',
  programRunId = 'programRunId',
  uploadOpen = 'uploadOpen',
  accountId = 'accountId',
  previousPage = 'previousPage',
  checkpoint = 'checkpoint',
  stripeStatus = 'stripeStatus',
  locus = 'locus',
  flowRunId = 'flowRunId',
  gene = 'gene',
  entityType = 'entityType',
  entityId = 'entityId',
  selectedIds = 'selectedIds',
  chartType = 'chartType',
  config = 'config',
  vadrConfigId = 'vadrConfigId',
  profileConfigId = 'profileConfigId',
  chartConfigId = 'chartConfigId',
}

// The most common combination of query params
const defaultProjParamsBase: QueryParams[] = [
  QueryParams.orgId,
  QueryParams.pid,
];
const defaultProjParams: QueryParams[] = [
  ...defaultProjParamsBase,
  QueryParams.panel,
];

const ORGANIZATIONS_SCOPED_PATHNAME = `/organizations`;
const ORGANIZATION_SCOPED_PATHNAME =
  ORGANIZATIONS_SCOPED_PATHNAME + `/[${QueryParams.orgId}]`;
const ORG_PLANS_PATHNAME = ORGANIZATION_SCOPED_PATHNAME + '/plans';
const PROJECTS_SCOPED_PATHNAME = ORGANIZATION_SCOPED_PATHNAME + '/projects';
const PROJECT_SCOPED_PATHNAME =
  PROJECTS_SCOPED_PATHNAME + `/[${QueryParams.pid}]`;
const WORKFLOW_RUNS_SCOPED_PATHNAME =
  PROJECT_SCOPED_PATHNAME + '/workflow-runs';
const WORKFLOWS_PATHNAME = PROJECT_SCOPED_PATHNAME + '/workflows';
const WORKFLOW_MANAGE_SCOPED_PATHNAME = `${PROJECT_SCOPED_PATHNAME}/workflow-manager`;
const MY_PROFILE_SCOPE_PATHNAME = '/profile';
const FORM_BENCH_PATHNAME = PROJECT_SCOPED_PATHNAME + '/bench';
const VECTORFLOW_PATHNAME = PROJECT_SCOPED_PATHNAME + '/vectorflow';
const GENE_TABLE_PATHNAME =
  VECTORFLOW_PATHNAME + `/[${QueryParams.indicationId}]`;
const VECTORS_PATHNAME = GENE_TABLE_PATHNAME + '/vectors';
const CONFIG_PATHNAME = GENE_TABLE_PATHNAME + '/config';
const BULK_UPLOAD_PATHNAME = VECTORFLOW_PATHNAME + '/upload';
const INDICATION_CONFIG_PATHNAME = CONFIG_PATHNAME + '/indication';
const GENE_CONFIG_PATHNAME =
  CONFIG_PATHNAME + '/gene' + `/[${QueryParams.gene}]`;
const VADR_CONFIG_PATHNAME =
  CONFIG_PATHNAME + '/vadr' + `/[${QueryParams.vadrConfigId}]`;
const PROFILE_CONFIG_PATHNAME =
  CONFIG_PATHNAME + '/profile' + `/[${QueryParams.profileConfigId}]`;
const CHART_CONFIG_PATHNAME =
  CONFIG_PATHNAME + '/chart' + `/[${QueryParams.chartConfigId}]`;
const VECTOR_RUN_PATHNAME = VECTORS_PATHNAME + `/[${QueryParams.programRunId}]`;
const VECTOR_EDIT_PATHNAME =
  PROJECT_SCOPED_PATHNAME + `/vectors/edit/[${QueryParams.entityId}]`;
const IGV_PATHNAME = PROJECT_SCOPED_PATHNAME + `/igv`;
const INVITES_PATHNAME = '/invitation';
const PROJECT_INVITES_PATHNAME =
  INVITES_PATHNAME + `/project/[${QueryParams.invitationId}]`;
const ORG_INVITES_PATHNAME =
  INVITES_PATHNAME + `/organization/[${QueryParams.invitationId}]`;
const JOURNEYS_PATHNAME = PROJECT_SCOPED_PATHNAME + '/journeys';
const JOURNEY_RUNS_PATHNAME = PROJECT_SCOPED_PATHNAME + '/journey-runs';
const JOURNEY_RUN_PATHNAME =
  JOURNEY_RUNS_PATHNAME + `/[${QueryParams.journeyRunId}]`;
const JOURNEY_RUN_PHASE_PATHNAME =
  JOURNEY_RUN_PATHNAME + `/[${QueryParams.phaseRunId}]`;
const JOURNEY_RUN_STEP_PATHNAME =
  JOURNEY_RUN_PHASE_PATHNAME + `/[${QueryParams.stepRunId}]`;
const MOLSTAR_PATHNAME = PROJECT_SCOPED_PATHNAME + `/molstar`;
const ENTITIES_PATHNAME = PROJECT_SCOPED_PATHNAME + '/entities';
const ENTITY_MANAGER_PATHNAME =
  ENTITIES_PATHNAME + `/[${QueryParams.entityType}]`;
const ENTITY_EDITOR_PATHNAME =
  ENTITY_MANAGER_PATHNAME + `/[${QueryParams.entityId}]`;
const ENTITY_CREATE_PATHNAME = ENTITY_MANAGER_PATHNAME + `/create`;

export type Breadcrumb = {
  id: string;
  name: string;
  href?: UrlObject;
};

const home: NavRouteWithParam = {
  pathname: '/',
};

const welcome: NavRouteWithParam = {
  pathname: '/welcome',
  queryKeys: [QueryParams.accountId],
};

const login: NavRouteWithParam = {
  pathname: '/auth/login',
  queryKeys: [QueryParams.destination],
};

const signup: NavRouteWithParam = {
  pathname: '/auth/signup',
  queryKeys: [QueryParams.accountId],
};

const forgotPassword: NavRouteWithParam = {
  pathname: '/auth/forgot-password',
};

const createAccount: NavRouteWithParam = {
  pathname: '/auth/create-account',
};

const resetPassword: NavRouteWithParam = {
  pathname: '/auth/reset-password',
  queryKeys: [
    QueryParams.mode,
    QueryParams.oobCode,
    QueryParams.apiKey,
    QueryParams.lang,
  ],
};

const revertMfa: NavRouteWithParam = {
  pathname: '/auth/revert-mfa',
  queryKeys: [
    QueryParams.mode,
    QueryParams.oobCode,
    QueryParams.apiKey,
    QueryParams.lang,
  ],
};

const verifyAndChangeEmail: NavRouteWithParam = {
  pathname: '/auth/verify-and-change-email',
  queryKeys: [
    QueryParams.mode,
    QueryParams.oobCode,
    QueryParams.apiKey,
    QueryParams.lang,
  ],
};

const recoverEmail: NavRouteWithParam = {
  pathname: '/auth/recover-email',
  queryKeys: [
    QueryParams.mode,
    QueryParams.oobCode,
    QueryParams.apiKey,
    QueryParams.lang,
  ],
};

const orgPlans: NavRouteWithParam = {
  pathname: ORG_PLANS_PATHNAME,
  queryKeys: [QueryParams.orgId, QueryParams.previousPage],
};

const project: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME,
  queryKeys: defaultProjParams,
};

const projectHome: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/home',
  queryKeys: defaultProjParams,
};

const projectMembers: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/members',
  queryKeys: defaultProjParams,
};

const workflowRuns: NavRouteWithParam = {
  pathname: WORKFLOW_RUNS_SCOPED_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.status,
    QueryParams.users,
    QueryParams.search,
  ],
};

const workflowRun: NavRouteWithParam = {
  pathname: WORKFLOW_RUNS_SCOPED_PATHNAME + `/[${QueryParams.runId}]`,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.runId,
    QueryParams.previousPageParams,
    QueryParams.selectedFile,
    QueryParams.supportFile,
    QueryParams.tab,
  ],
};

const workflowsList: NavRouteWithParam = {
  pathname: WORKFLOWS_PATHNAME,
  queryKeys: [...defaultProjParams, QueryParams.category],
};

const workflowDocs: NavRouteWithParam = {
  pathname:
    WORKFLOWS_PATHNAME +
    `/[${QueryParams.repoName}]/[${QueryParams.workflowName}]`,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.repoName,
    QueryParams.workflowName,
    QueryParams.category,
    QueryParams.tab,
    QueryParams.version,
    QueryParams.uploadedOrg,
    QueryParams.uploadedProj,
  ],
};

const workflowLauncher: NavRouteWithParam = {
  pathname:
    WORKFLOWS_PATHNAME +
    `/[${QueryParams.repoName}]/[${QueryParams.workflowName}]/[${QueryParams.groupKey}]`,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.repoName,
    QueryParams.workflowName,
    QueryParams.groupKey,
    QueryParams.version,
    QueryParams.uploadedOrg,
    QueryParams.uploadedProj,
    QueryParams.selectedFile,
    QueryParams.supportFile,
  ],
};

const workflowManager: NavRouteWithParam = {
  pathname: WORKFLOW_MANAGE_SCOPED_PATHNAME,
  queryKeys: defaultProjParams,
};

const workflowImport: NavRouteWithParam = {
  pathname: `${WORKFLOW_MANAGE_SCOPED_PATHNAME}/import`,
  queryKeys: defaultProjParams,
};

const allBuilds: NavRouteWithParam = {
  pathname: WORKFLOW_MANAGE_SCOPED_PATHNAME + '/builds',
  queryKeys: defaultProjParams,
};

const workflowVersionsManager: NavRouteWithParam = {
  pathname: `${WORKFLOW_MANAGE_SCOPED_PATHNAME}/workflow/[${QueryParams.workflowId}]`,
  queryKeys: [...defaultProjParams, QueryParams.workflowId],
};

const checkRuns: NavRouteWithParam = {
  pathname:
    WORKFLOW_MANAGE_SCOPED_PATHNAME +
    `/workflow/[${QueryParams.workflowId}]/build/[${QueryParams.checkSuiteId}]`,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.workflowId,
    QueryParams.checkSuiteId,
  ],
};

const workflowBuilds: NavRouteWithParam = {
  pathname:
    WORKFLOW_MANAGE_SCOPED_PATHNAME +
    `/workflow/[${QueryParams.workflowId}]/builds`,
  queryKeys: [...defaultProjParams, QueryParams.workflowId],
};

const vault: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/vault',
  queryKeys: [
    ...defaultProjParams,
    QueryParams.path,
    QueryParams.search,
    QueryParams.selectedFile,
    QueryParams.supportFile,
    QueryParams.uploadOpen,
  ],
};

const editor: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/editor',
  queryKeys: [
    ...defaultProjParams,
    QueryParams.selectedFile,
    // a separate query param for editing a file
    // this way we can open/close the metadata panel (controled by `selectedFile`)
    // while editing the same file.
    QueryParams.editingFile,
  ],
};

const checkVerifyEmail: NavRouteWithParam = {
  pathname: '/auth/check-verify-email',
  queryKeys: [QueryParams.email],
};

const verifyEmail: NavRouteWithParam = {
  pathname: '/auth/verify-email',
  queryKeys: [
    QueryParams.email,
    QueryParams.mode,
    QueryParams.oobCode,
    QueryParams.apiKey,
    QueryParams.lang,
  ],
};

const organizationRoute: NavRouteWithParam = {
  pathname: ORGANIZATION_SCOPED_PATHNAME,
  queryKeys: [QueryParams.orgId],
};

const organizationsRoute: NavRouteWithParam = {
  pathname: '/organizations',
};

const myProfileRoute: NavRouteWithParam = {
  pathname: MY_PROFILE_SCOPE_PATHNAME,
};

const refreshTokenRoute: NavRouteWithParam = {
  pathname: '/refresh-token',
};

const igvRoute: NavRouteWithParam = {
  pathname: IGV_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.referenceId,
    QueryParams.encodedFiles,
    QueryParams.locus,
  ],
};

const formBenchRoute: NavRouteWithParam = {
  pathname: FORM_BENCH_PATHNAME,
  queryKeys: defaultProjParams,
};

const formBenchActivityRoute: NavRouteWithParam = {
  pathname: `${FORM_BENCH_PATHNAME}/activity`,
  queryKeys: [...defaultProjParams, QueryParams.search],
};

const formBenchLaunchRoute: NavRouteWithParam = {
  pathname: `${FORM_BENCH_PATHNAME}/launch`,
  queryKeys: defaultProjParams,
};

const formBenchLaunchInstanceRoute: NavRouteWithParam = {
  pathname: `${FORM_BENCH_PATHNAME}/launch/[${QueryParams.frn}]`,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.frn,
    QueryParams.selectedFile,
    QueryParams.selectedFolder,
  ],
};

const formBenchManageRoute: NavRouteWithParam = {
  pathname: `${FORM_BENCH_PATHNAME}/manage`,
  queryKeys: defaultProjParams,
};

const formBenchManageCreateRoute: NavRouteWithParam = {
  pathname: `${FORM_BENCH_PATHNAME}/manage/create`,
  queryKeys: defaultProjParams,
};

const formBenchManageEditRoute: NavRouteWithParam = {
  pathname: `${FORM_BENCH_PATHNAME}/manage/edit/[${QueryParams.formBenchId}]`,
  queryKeys: [...defaultProjParams, QueryParams.formBenchId],
};

const loginToken: NavRouteWithParam = {
  pathname: '/auth/login-token',
};

const projectInviteRoute: NavRouteWithParam = {
  pathname: PROJECT_INVITES_PATHNAME,
  queryKeys: [QueryParams.invitationId],
};

const orgInviteRoute: NavRouteWithParam = {
  pathname: ORG_INVITES_PATHNAME,
  queryKeys: [QueryParams.invitationId],
};

const vectorflow: NavRouteWithParam = {
  pathname: VECTORFLOW_PATHNAME,
  queryKeys: [...defaultProjParams],
};

const vectorflowCreate: NavRouteWithParam = {
  pathname: `${VECTORFLOW_PATHNAME}/create`,
  queryKeys: [...defaultProjParams, QueryParams.indicationId, QueryParams.gene],
};

const geneVectorsTable: NavRouteWithParam = {
  pathname: GENE_TABLE_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.indicationId,
    QueryParams.gene,
    QueryParams.selectedFile,
    QueryParams.config,
    QueryParams.chartType,
  ],
};

const vectors: NavRouteWithParam = {
  pathname: VECTORS_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.indicationId,
    QueryParams.entityId,
    QueryParams.gene,
    QueryParams.selectedFile,
  ],
};

const vectorRun: NavRouteWithParam = {
  pathname: VECTOR_RUN_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.groupKey,
    QueryParams.indicationId,
    QueryParams.programRunId,
  ],
};

const vectorEdit: NavRouteWithParam = {
  pathname: VECTOR_EDIT_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.groupKey,
    QueryParams.indicationId,
    QueryParams.entityId,
  ],
};

const journeys: NavRouteWithParam = {
  pathname: JOURNEYS_PATHNAME,
  queryKeys: defaultProjParams,
};

const journeyRunPhase: NavRouteWithParam = {
  pathname: JOURNEY_RUN_PHASE_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.journeyRunId,
    QueryParams.phaseRunId,
  ],
};
const journeyRunStep: NavRouteWithParam = {
  pathname: JOURNEY_RUN_STEP_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.journeyRunId,
    QueryParams.phaseRunId,
    QueryParams.stepRunId,
  ],
};

const journeyRuns: NavRouteWithParam = {
  pathname: JOURNEY_RUNS_PATHNAME,
  queryKeys: defaultProjParams,
};

const journeyRun: NavRouteWithParam = {
  pathname: JOURNEY_RUN_PATHNAME,
  queryKeys: [...defaultProjParams, QueryParams.journeyRunId],
};

const downloads: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/downloads',
  queryKeys: [...defaultProjParams, QueryParams.download],
};

const molstarViewer: NavRouteWithParam = {
  pathname: MOLSTAR_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.structureUrl,
    QueryParams.structureUrlFormat,
  ],
};

const logout: NavRouteWithParam = {
  pathname: '/auth/logout',
  queryKeys: [QueryParams.destination],
};

const onboarding: NavRouteWithParam = {
  pathname: '/onboarding',
  queryKeys: [QueryParams.accountId],
};

const synthAssaysLanding: NavRouteWithParam = {
  pathname: '/synth-assays-welcome',
};

const synthAssays: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/synth-assays',
  queryKeys: [...defaultProjParamsBase],
};
const synthAssaysActivity: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/synth-assays/activity',
  queryKeys: [...defaultProjParamsBase],
};
const synthAssaysCreate: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/synth-assays/create',
  queryKeys: [...defaultProjParamsBase],
};
const caas: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/caas',
  queryKeys: [...defaultProjParamsBase],
};
const caasActivity: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/caas/activity',
  queryKeys: [...defaultProjParamsBase],
};
const caasCreate: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/caas/create',
  queryKeys: [...defaultProjParamsBase],
};
const synthAssayResult: NavRouteWithParam = {
  pathname:
    PROJECT_SCOPED_PATHNAME + '/synth-assays' + `/[${QueryParams.entityId}]`,
  queryKeys: [
    ...defaultProjParamsBase,
    QueryParams.entityId,
    QueryParams.stripeStatus,
  ],
};
const caasResult: NavRouteWithParam = {
  pathname: PROJECT_SCOPED_PATHNAME + '/caas' + `/[${QueryParams.flowRunId}]`,
  queryKeys: [...defaultProjParamsBase, QueryParams.flowRunId],
};
const caasLauncher: NavRouteWithParam = {
  pathname:
    PROJECT_SCOPED_PATHNAME +
    '/caas' +
    `/[${QueryParams.flowRunId}]/launcher/[${QueryParams.groupKey}]`,
  queryKeys: [
    ...defaultProjParamsBase,
    QueryParams.flowRunId,
    QueryParams.groupKey,
  ],
};
const caasLauncherRedirect: NavRouteWithParam = {
  pathname:
    PROJECT_SCOPED_PATHNAME + '/caas' + `/[${QueryParams.flowRunId}]/launcher/`,
  queryKeys: [...defaultProjParamsBase, QueryParams.flowRunId],
};

const entities: NavRouteWithParam = {
  pathname: ENTITIES_PATHNAME,
  queryKeys: [...defaultProjParams],
};

const entityTypeManager: NavRouteWithParam = {
  pathname: ENTITY_MANAGER_PATHNAME,
  queryKeys: [...defaultProjParams, QueryParams.entityType],
};

const entityEditor: NavRouteWithParam = {
  pathname: ENTITY_EDITOR_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.entityType,
    QueryParams.entityId,
  ],
};

const entityCreate: NavRouteWithParam = {
  pathname: ENTITY_CREATE_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.entityType,
    QueryParams.selectedIds,
  ],
};

const indicationConfig: NavRouteWithParam = {
  pathname: INDICATION_CONFIG_PATHNAME,
  queryKeys: [...defaultProjParams, QueryParams.indicationId],
};

const geneConfig: NavRouteWithParam = {
  pathname: GENE_CONFIG_PATHNAME,
  queryKeys: [...defaultProjParams, QueryParams.indicationId, QueryParams.gene],
};

const vadrConfig: NavRouteWithParam = {
  pathname: VADR_CONFIG_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.indicationId,
    QueryParams.vadrConfigId,
  ],
};

const profileConfig: NavRouteWithParam = {
  pathname: PROFILE_CONFIG_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.indicationId,
    QueryParams.profileConfigId,
  ],
};

const chartConfig: NavRouteWithParam = {
  pathname: CHART_CONFIG_PATHNAME,
  queryKeys: [
    ...defaultProjParams,
    QueryParams.indicationId,
    QueryParams.chartConfigId,
    QueryParams.gene,
  ],
};

const vectorBulkUpload: NavRouteWithParam = {
  pathname: BULK_UPLOAD_PATHNAME,
  queryKeys: [...defaultProjParams, QueryParams.indicationId, QueryParams.gene],
};

/**
 * Utils function to create a href (with router navigation)
 * by retrieving the route params from the router
 * @param navRoute one of the routes described in this file
 * @param router NextRouter
 * @param params key-value pairs to add to path as query or path params
 * @returns
 */
//  TODO: Refactor to require organization for all routes (must also refactor all routes)
export function getHref(
  router: NextRouter,
  navRoute: NavRouteWithParam,
  params: { [key in QueryParams]?: string } = {},
): UrlObject {
  if (!navRoute.queryKeys || !navRoute.queryKeys.length) {
    return { pathname: navRoute.pathname };
  } else {
    const query: { [key: string]: string } = {};

    navRoute.queryKeys.forEach(key => {
      if (params[key] !== undefined) {
        // @ts-expect-error (was seeing error that query[key] might be undefined despite conditional)
        query[key] = params[key];
      } else if (key !== QueryParams.search) {
        query[key] = getQueryParams(router, key);
      }
    });

    return { pathname: navRoute.pathname, query };
  }
}

function isSubPath(currentPathname: string, toTestPathname: string) {
  const parentPathname = currentPathname.split('/').slice(0, -1).join('/');
  return parentPathname === toTestPathname;
}

/**
 * Finds if the { NavItem } pathname matches the current path
 * or if it matches one level up.
 * @param currentPathname pathname from the router
 * @param navItemPathname pathname from the current nav item
 * @returns true if the navitem should be selected
 */
export function isNavItemInRoute(
  currentPathname: string,
  navItemPathname: string,
): boolean {
  return (
    currentPathname === navItemPathname ||
    isSubPath(currentPathname, navItemPathname)
  );
}

/**
 * Checks if a route is dependent on having a project id.
 * @param queryKeys the list of query params needed for a given route
 * @returns true if the route depends on a project
 */
export function dependsOnProject(queryKeys: QueryParams[] | undefined) {
  return queryKeys?.includes(QueryParams.pid);
}

export function updateRouterQuery(
  router: NextRouter,
  paramName: QueryParams,
  value: string | string[],
) {
  const newQuery = { ...router.query };
  newQuery[paramName] = value;
  router.push({ pathname: router.pathname, query: newQuery }, undefined, {
    shallow: true,
  });
}
export function updateRouterQueries(
  router: NextRouter,
  paramNames: QueryParams[],
  values: (string | string[])[],
) {
  const newQuery = {
    ...router.query,
    ...Object.fromEntries(paramNames.map((name, i) => [name, values[i]])),
  };
  router.push({ pathname: router.pathname, query: newQuery }, undefined, {
    shallow: true,
  });
}

export const navRoutes = {
  welcome,
  home,
  login,
  signup,
  forgotPassword,
  createAccount,
  project,
  projectHome,
  projectMembers,
  workflowRuns,
  workflowRun,
  workflowsList,
  workflowLauncher,
  workflowManager,
  workflowImport,
  workflowVersionsManager,
  resetPassword,
  revertMfa,
  verifyAndChangeEmail,
  recoverEmail,
  vault,
  editor,
  organization: organizationRoute,
  organizations: organizationsRoute,
  checkVerifyEmail,
  verifyEmail,
  workflowDocs,
  myProfile: myProfileRoute,
  refreshToken: refreshTokenRoute,
  igv: igvRoute,
  formBench: formBenchRoute,
  formBenchActivity: formBenchActivityRoute,
  formBenchLaunch: formBenchLaunchRoute,
  formBenchLaunchInstance: formBenchLaunchInstanceRoute,
  formBenchManage: formBenchManageRoute,
  formBenchManageCreate: formBenchManageCreateRoute,
  formBenchManageEdit: formBenchManageEditRoute,
  loginToken: loginToken,
  projectInvite: projectInviteRoute,
  orgInvite: orgInviteRoute,
  allBuilds,
  workflowBuilds,
  checkRuns,
  geneVectorsTable,
  vectorflow,
  vectorflowCreate,
  vectors,
  vectorRun,
  vectorEdit,
  journeys,
  journeyRunPhase,
  journeyRunStep,
  journeyRuns,
  journeyRun,
  downloads,
  molstarViewer,
  logout,
  orgPlans,
  onboarding,
  synthAssaysLanding,
  synthAssays,
  synthAssaysActivity,
  synthAssaysCreate,
  synthAssayResult,
  caas,
  caasActivity,
  caasCreate,
  caasResult,
  caasLauncher,
  caasLauncherRedirect,
  entities,
  entityTypeManager,
  entityEditor,
  entityCreate,
  indicationConfig,
  geneConfig,
  vadrConfig,
  profileConfig,
  chartConfig,
  vectorBulkUpload,
};
